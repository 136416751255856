import styled from 'styled-components';

import vars from 'variables';

import arrow from 'assets/OurPartners/topright-blue.svg';

const Root = styled.div`
  ul {
    margin: 110px 0 120px;
  }

  li {
    margin-bottom: 20px;
    padding: 15px;
    background: ${vars.colors.marineBlue};
    border-radius: 8px;
  }

  li > div {
    flex: 0 0 50%;
  }

  li .logo {
    background-color: ${vars.colors.background};
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 7px;
  }

  li a {
    padding-right: 18px;
    font-weight: bold;
    text-transform: uppercase;
    background: url(${arrow}) no-repeat right center;
  }

  ${vars.desktop.mediaQuery} {
    li {
      display: flex;
    }

    li .content {
      padding: 40px 0;
    }

    li h2,
    li p,
    li a {
      margin: 0 60px 0 80px;
      display: inline-block;
    }

    li h2 {
      margin-bottom: 24px;
      font-size: 1.65rem;
      font-weight: bold;
    }

    li p {
      margin-bottom: 28px;
      color: ${vars.colors.grey};
      line-height: 160%;
    }

    li a {
      font-size: 0.75rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    ul {
      margin-top: 55px;
    }

    li .logo {
      height: 250px;
      background-size: 60% auto;
    }

    li h2 {
      margin: 20px 0 11px;
      font-size: 1.25rem;
      font-weight: 600;
    }

    li p {
      margin-bottom: 30px;
    }

    li a {
      font-size: 0.6rem;
    }
  }
`;

export { Root };

import React from 'react';
import { Root } from './style';

import PageHeader from 'components/Common/PageHeader';

import vars from 'variables';

const OurPartners = ({pageContext: { partners }}) => {
  return (
    <Root className="body-width">
      <PageHeader page="ourPartners"/>
      <ul>
        {(partners || []).map(partner =>
          <li key={partner.id}>
            <div className="logo" style={{backgroundImage: `url(${vars.assetsPrefix}/${partner.logo})`}}/>
            <div className="content">
              <div>
                <h2>{ partner.name }</h2>
                <p>{ partner.backgroundInfo }</p>
                <a href={ partner.websiteURL } target="_blank" rel="noreferrer">Visit website</a>
              </div>
            </div>
          </li>
        )}
      </ul>
    </Root>
  );
};

export default OurPartners;
